import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/account/register.vue"),
    meta: {
      title: "Register",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("../views/account/forgot-password.vue"),
    meta: {
      title: "Forgot Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout",
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear();
        sessionStorage.clear();
        next();
      },
    },
    component: () => import("../views/account/logout"),
  },

  {
    path: "/reset-password/:token",
    name: "Reset Password",
    component: () => import("../views/account/reset-password.vue"),
    meta: {
      title: "Reset Password",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        // Continue to the login page
        next();
      },
    },
  },

  {
    path: "/",
    name: "default",
    meta: {
      title: "Dashboard",
      authRequired: true,
    },
    component: () => import("../views/pages/starter.vue"),
  },

  // Menus
  {
    path: "/settings/menus",
    name: "Menus",
    meta: { title: "Menu", authRequired: true },
    component: () => import("../views/maintenance/menus/index"),
  },
  {
    path: "/settings/menus/form",
    name: "AddMenu",
    meta: { title: "Add Menu", authRequired: true },
    component: () => import("../views/maintenance/menus/form"),
  },
  {
    path: "/settings/menus/form/:id",
    name: "EditMenu",
    meta: { title: "Edit Menu", authRequired: true },
    component: () => import("../views/maintenance/menus/form"),
  },

  // Permissions
  {
    path: "/settings/permissions",
    name: "Permissions",
    meta: { title: "Permission", authRequired: true },
    component: () => import("../views/user-management/permissions/index"),
  },
  {
    path: "/settings/permissions/form",
    name: "AddPermission",
    meta: { title: "Add Permission", authRequired: true },
    component: () => import("../views/user-management/permissions/form"),
  },
  {
    path: "/settings/permissions/form/:id",
    name: "EditPermission",
    meta: { title: "Edit Permission", authRequired: true },
    component: () => import("../views/user-management/permissions/form"),
  },

  // Roles
  {
    path: "/settings/roles",
    name: "Roles",
    meta: { title: "Role", authRequired: true },
    component: () => import("../views/user-management/roles/index"),
  },
  {
    path: "/settings/roles/:id",
    name: "ViewRole",
    meta: { title: "View Role", authRequired: true },
    component: () => import("../views/user-management/roles/info"),
  },
  {
    path: "/settings/roles/form",
    name: "AddRole",
    meta: { title: "Add Role", authRequired: true },
    component: () => import("../views/user-management/roles/form"),
  },
  {
    path: "/settings/roles/form/:id",
    name: "EditRole",
    meta: { title: "Edit Role", authRequired: true },
    component: () => import("../views/user-management/roles/form"),
  },

  // Modules
  {
    path: "/settings/modules",
    name: "Modules",
    meta: { title: "Module", authRequired: true },
    component: () => import("../views/user-management/modules/index"),
  },
  {
    path: "/settings/modules/form",
    name: "AddModule",
    meta: { title: "Add Module", authRequired: true },
    component: () => import("../views/user-management/modules/form"),
  },
  {
    path: "/settings/modules/form/:id",
    name: "EditModule",
    meta: { title: "Edit Module", authRequired: true },
    component: () => import("../views/user-management/modules/form"),
  },

  // Users
  {
    path: "/settings/users",
    name: "Users",
    meta: { title: "User", authRequired: true },
    component: () => import("../views/user-management/users/index"),
  },
  {
    path: "/settings/users/:id",
    name: "UserProfile",
    meta: { title: "User Profile", authRequired: true },
    component: () => import("../views/user-management/users/profile"),
  },
  {
    path: "/users/profile/:id",
    name: "UserProfile2",
    meta: { title: "User Profile", authRequired: true },
    component: () => import("../views/user-management/users/user-profile"),
  },
  {
    path: "/settings/users/form",
    name: "AddUser",
    meta: { title: "Add User", authRequired: true },
    component: () => import("../views/user-management/users/form"),
  },
  {
    path: "/settings/users/form/:id",
    name: "EditUser",
    meta: { title: "Edit User", authRequired: true },
    component: () => import("../views/user-management/users/form"),
  },

  {
    path: "/user/profile",
    name: "UserProfileView",
    meta: { title: "User", authRequired: true },
    component: () => import("../views/user-management/users/user-profile"),
  },

  // Commission
  {
    path: "/commission/soa",
    name: "SOAList",
    meta: { title: "Statement of Accounts", authRequired: true },
    component: () => import("../views/commission-management/soa/index"),
  },

  {
    path: "/commission/transaction",
    name: "CommissionTransaction",
    meta: { title: "Commission Transaction", authRequired: true },
    component: () => import("../views/commission-management/transaction"),
  },

  // Maintenance

  // Operating Unit
  {
    path: "/maintenance/ou/index",
    name: "OperatingUnit",
    meta: { title: "Operating Unit", authRequired: true },
    component: () => import("../views/maintenance/ou/index"),
  },

  // Position
  {
    path: "/maintenance/position/index",
    name: "Position",
    meta: { title: "Position", authRequired: true },
    component: () => import("../views/maintenance/position/index"),
  },

  // Sales Team
  {
    path: "/maintenance/sales-team/index",
    name: "SalesTeam",
    meta: { title: "Sales Team", authRequired: true },
    component: () => import("../views/maintenance/sales-team/index"),
  },

  // Property Listing
  {
    path: "/property/list",
    name: "PropertyListing",
    meta: { title: "Property Listing", authRequired: true },
    component: () => import("../views/property-management/general-list"),
  },

  // Property Master List
  {
    path: "/property/master-list",
    name: "MasterList",
    meta: { title: "Reserved Properties", authRequired: true },
    component: () => import("../views/property-management/master-list"),
  },

  // Area
  {
    path: "/maintenance/area",
    name: "Area",
    meta: { title: "Area", authRequired: true },
    component: () => import("../views/maintenance/area"),
  },

  // House Model
  {
    path: "/maintenance/house-model",
    name: "HouseModel",
    meta: { title: "House Model", authRequired: true },
    component: () => import("../views/maintenance/house-model"),
  },

  // Developer
  {
    path: "/maintenance/developer",
    name: "Developer",
    meta: { title: "Developer", authRequired: true },
    component: () => import("../views/maintenance/developer"),
  },

  // Project
  {
    path: "/maintenance/project",
    name: "Project",
    meta: { title: "JBC PROPERTY LISTING", authRequired: true },
    component: () => import("../views/maintenance/project"),
  },

  // Prospect Status
  {
    path: "/maintenance/prospect-status",
    name: "ProspectStatus",
    meta: { title: "Prospect Status", authRequired: true },
    component: () => import("../views/maintenance/prospect-status"),
  },

  // Prospect
  {
    path: "/buyer-management/prospect",
    name: "Prospect",
    meta: { title: "Lead List", authRequired: true },
    component: () => import("../views/buyer-management/prospect"),
  },

  // Buyer
  {
    path: "/buyer-management/list",
    name: "Buyer",
    meta: { title: "Buyer", authRequired: true },
    component: () => import("../views/buyer-management/buyer"),
  },

  // Sales
  {
    path: "/sales/record",
    name: "SalesRecord",
    meta: { title: "Sales Record", authRequired: true },
    component: () => import("../views/sales/record"),
  },
  {
    path: "/sales/report",
    name: "SalesReport",
    meta: { title: "Sales Report", authRequired: true },
    component: () => import("../views/sales/report"),
  },

  // Division
  {
    path: "/maintenance/division",
    name: "Division",
    meta: { title: "Division", authRequired: true },
    component: () => import("../views/maintenance/division"),
  },

  // Collection Routes
  // Developers Ledger
  {
    path: "/collection/developers-ledger",
    name: "DevelopersLedger",
    meta: { title: "Developers Ledger", authRequired: true },
    component: () => import("../views/collection-management/developers-ledger"),
  },
  // Collection Record
  {
    path: "/collection/record",
    name: "CollectionRecords",
    meta: { title: "Collection Records", authRequired: true },
    component: () => import("../views/collection-management/collection-record"),
  },

  // Collection Report
  {
    path: "/collection/report",
    name: "CollectionReports",
    meta: { title: "Collection Reports", authRequired: true },
    component: () => import("../views/collection-management/collection-report"),
  },

  // Receivables
  {
    path: "/collection/receivables",
    name: "Receivables",
    meta: { title: "Aging Receivables Reports", authRequired: true },
    component: () => import("../views/collection-management/receivables"),
  },

  // Bank
  {
    path: "/maintenance/bank",
    name: "Bank",
    meta: { title: "Bank", authRequired: true },
    component: () => import("../views/maintenance/bank"),
  },

  // Approver
  {
    path: "/maintenance/approver",
    name: "Approver",
    meta: { title: "Approver", authRequired: true },
    component: () => import("../views/maintenance/approver"),
  },

  // Unit Type
  {
    path: "/maintenance/unit-type",
    name: "UnitType",
    meta: { title: "Unit Type", authRequired: true },
    component: () => import("../views/maintenance/unit-type"),
  },

  // Sub Unit Type
  {
    path: "/maintenance/sub-unit-type",
    name: "SubUnitType",
    meta: { title: "Sub Unit Type", authRequired: true },
    component: () => import("../views/maintenance/sub-unit-type"),
  },

  // Cash Advance
  {
    path: "/cash-advance/record",
    name: "CashAdvance",
    meta: { title: "Cash Advance", authRequired: true },
    component: () => import("../views/cash-advance/record"),
  },

  // Ext Cash Advance
  {
    path: "/cash-advance/ext/:id",
    name: "CAExt",
    meta: { title: "Cash Advance" },
    component: () => import("../views/cash-advance/record/ext_ca"),
  },

  // Ext Cash Advance Approver
  {
    path: "/cash-advance/appr/ext/:id",
    name: "CAApprExt",
    meta: { title: "Cash Advance" },
    component: () => import("../views/cash-advance/record/ext_ca_appr"),
  },

  // Commission Records
  {
    path: "/commission/record",
    name: "CommissionRecords",
    meta: { title: "Commission Records", authRequired: true },
    component: () => import("../views/commission-management/record"),
  },

  // File Forms
  {
    path: "/files/downloadable-form",
    name: "DownloadableForms",
    meta: { title: "Downloadable Forms", authRequired: true },
    component: () => import("../views/files/downloadable-form"),
  },

  // File Memos
  {
    path: "/files/memos",
    name: "Memos",
    meta: { title: "Memos", authRequired: true },
    component: () => import("../views/files/memos"),
  },

  // File Employee Forms
  {
    path: "/files/employee/downloadable-form",
    name: "EmployeeDownloadableForms",
    meta: { title: "Employee Downloadable Forms", authRequired: true },
    component: () => import("../views/files/employee/downloadable-form"),
  },

  // File Employee Memos
  {
    path: "/files/employee/memos",
    name: "EmployeeMemos",
    meta: { title: "Employee Memos", authRequired: true },
    component: () => import("../views/files/employee/memos"),
  },

  // File Employee Payslip
  {
    path: "/files/employee/payslip",
    name: "EmployeePayslip",
    meta: { title: "Employee Payslip", authRequired: true },
    component: () => import("../views/files/employee/payslip"),
  },

  // Expenses Management
  {
    path: "/expense/transaction",
    name: "ExpenseTransaction",
    meta: { title: "Expense Transaction", authRequired: true },
    component: () => import("../views/expense-management/transaction"),
  },
  {
    path: "/expense/records",
    name: "ExpenseRecord",
    meta: { title: "Expense Records", authRequired: true },
    component: () => import("../views/expense-management/records"),
  },
  {
    path: "/expense/reports",
    name: "ExpenseReports",
    meta: { title: "Expense Reports", authRequired: true },
    component: () => import("../views/expense-management/reports"),
  },

  // Month Profit and Loss Report
  {
    path: "/reports/monthly-profit-and-loss",
    name: "ProfitLossReport",
    meta: { title: "Profit and Loss Report", authRequired: true },
    component: () => import("../views/reports/monthly-profit-loss"),
  },
];