import moment from 'moment';

export function formatAmount(amount) {
  if (typeof amount !== 'undefined') {
    if (typeof amount !== 'string') {
      amount = amount.toString();
    }
  } else {
    amount = '0.00';
  }

  const cleanedAmount = amount.replace(/,/g, '');

  if (isNaN(cleanedAmount) || cleanedAmount == "") {
    return '0.00';
  }

  let formattedAmount = parseFloat(cleanedAmount).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  if (formattedAmount.includes('-')) {
    formattedAmount = `(${formattedAmount.replace('-', '')})`;
  }

  return formattedAmount;
}

export function unformatAmount(formattedAmount) {
  if (typeof formattedAmount !== 'undefined') {
    if (typeof formattedAmount !== 'string') {
      formattedAmount = formattedAmount.toString();
    }
  } else {
    // Handle the case when 'amount' is undefined
    formattedAmount = '0.00'; // You can set it to '0' if you prefer
  }

  const unformattedAmount = formattedAmount.replace(/[^\d.-]/g, '');
  if (isNaN(unformattedAmount) || unformattedAmount == "") {
    return '0.00';
  }
  return parseFloat(unformattedAmount).toFixed(2);
}

// import moment

export function formatDate(date, format = 'YYYY-MM-DD') {
  return moment(date).format(format);
}

export function formatTime(time, format = 'HH:mm:ss') {
  return moment(time).format(format);
}

export function formatDateTime(datetime, format = 'YYYY-MM-DD HH:mm') {
  return moment(datetime).format(format);
}