<template>
  <router-view></router-view>
</template>
 
 <script>
import { ABILITY_TOKEN } from "@casl/vue";
import { fetchPermissions, fetchOUAccess } from "@/helpers/abilities.js";

export default {
  name: "App",
  inject: {
    $ability: { from: ABILITY_TOKEN },
  },
  components: {},
  methods: {
    loadPermissions() {
      if (localStorage.getItem("jwt")) {
        let permissions = localStorage.getItem("permissions")
          ? JSON.parse(localStorage.getItem("permissions"))
          : [];
        if (
          permissions == undefined ||
          permissions == null ||
          permissions.length == 0
        ) {
          fetchPermissions();
        }
      }
    },
    loadOUAccess() {
      if (localStorage.getItem("jwt")) {
        let ouAccess = localStorage.getItem("ouAccess")
          ? JSON.parse(localStorage.getItem("ouAccess"))
          : [];
        if (ouAccess == undefined || ouAccess == null || ouAccess.length == 0) {
          fetchOUAccess();
        }
      }
    },
  },
  computed: {
    rules() {
      return this.$store.state.rules || [];
    },
  },
  created() {
    this.$store.commit("getPermissions");
    this.$store.commit("getRules");
    this.$ability.update(this.rules);
  },
  mounted() {
    this.loadPermissions();
    this.loadOUAccess();
  },
};
</script>
 